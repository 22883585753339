<template>
  <div>
    <!-- Table Container Card -->
   
    <div class="w-full" id="Scroll">
      <vx-card style="border-radius: 5px;">
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="refReservationSessionTable"
            :max-items="itemsPerPage"
            :data="Model"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            class="position-relative"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <div class="flex flex-wrap-reverse items-center">
                <span v-if="queriedItems > 0" class="mr-2 text-xl"
                  >{{ $t("Total") }} :
                </span>
                <span v-if="queriedItems > 0" class="text-primary text-xl">
                  {{ queriedItems }}
                </span>
              </div>
            </div>

            <template slot="thead">
              <vs-th >{{ $t("Doctors") }}</vs-th>
              <vs-th >{{ $t("AppointmentDate") }}</vs-th>
              <vs-th >{{ $t("UploadDate") }}</vs-th>
              <vs-th >{{ $t("Status") }}</vs-th>
              <vs-th >{{ $t("PaymentStatus") }}</vs-th>
              <vs-th >{{ $t("Time") }}</vs-th>
              <vs-th>{{ $t("Actions") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  
                  <vs-td>
                    <b-media vertical-align="center">
                      
                      <template #aside>
                        <imageLazy
                        :imageStyle="{ 'border-radius': '15px','width': '30px','height':'30px'}"
                        :imageClass="'rounded-full'"
                        :src="baseURL + tr.Doctor.ProfileImagePath"
                        placeHolderType="doctor"
                    />
                 
                  </template>
                  <p class="font-weight-bold d-block text-nowrap">
                    # {{ $t("Dr") }}.{{tr.Doctor.Name }}
                  </p>
                </b-media>
                  </vs-td>

                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ tr.DoctorSession.DateFormatted}}
                    </p>
                  </vs-td>

                  <vs-td>
                    <p>{{ tr.DoctorSession.DateFormatted }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">
                      <span :dir="$vs.rtl ? 'ltr' : 'ltr'">
                        <p v-if="tr.Status">{{tr.Status.Name }}</p>
                    </span> 
                    </p>
                  </vs-td>
                  <vs-td>
                     
                      <p v-if="tr.PaymentStatus">
                     {{ tr.PaymentStatus.Name }}          
                    </p>
                  </vs-td>
                  
                  <vs-td>
                    {{ $t("From") }} : {{ tr.DoctorSession.DateFrom.slice(11, 16) }}
                    <br />
                    {{ $t("To") }}: {{ tr.DoctorSession.DateTo.slice(11, 16) }}
                  </vs-td>
               
                  <vs-td>
                    <div
                    class="text-nowrap"
                   :class="[
                     (tr.PaymentStatusID != 5 && tr.StatusID != 5) ||
                      tr.StatusID == 3
                      ? ' bg-danger text-white round '
                      : 'bg-primary text-white round',
                     ]"
                    >
    
                    <div
                    class="handclick p-2"
                    @click="PayNow(tr.item)"
      
                    v-if="
                        tr.PaymentStatusID == 1 &&
                        tr.StatusID != 5 &&
                        tr.StatusID != 3"
                   >
                   {{ $t("PayNow") }}
                 <feather-icon icon="DollarSignIcon" svgClasses="h-4 w-4" class="ml-1" />
    
                </div>
                <div
                    class="handclick"
                    v-if="tr.StatusID == 5 || tr.PatientJoined"
                    >
                {{ $t("Done") }}
   
              </div>
              <div class="handclick w-full pr-2" v-if="tr.StatusID == 3">
    
                {{ $t("Canceled") }}
    
    
              </div>
    
    
              <div
              v-if="
                   checkDate(tr.DoctorSession.DateFrom) &&
                   tr.PaymentStatusID == 5 &&
                   tr.StatusID != 5 &&
                   tr.StatusID != 3 &&
                  !tr.PatientJoined"
              >
      
              <div>
       
                <feather-icon icon="LinkIcon" svgClasses="h-4 w-4" class="ml-1" />
      
              </div>
      
              <p style="cursor: pointer" @click="joinMeeting(tr)">
        
                {{ $t("Join") }}
      
              </p>
    
            </div>
    
            <div v-else>
     
              <p
                   style="text-align: center; font-weight: bold"
                   class="mt-1"
                   v-if="
                        tr.StatusID != 5 &&
                        tr.PaymentStatusID == 5 &&
                        !tr.PatientJoined">
        
        {{ $t("CommingSoon") }}
     
      </p>
    
    </div>

    <div>
      <b-row
        v-if="
          tr.StatusID != 5 &&
          tr.StatusID != 3 &&
          !tr.PatientJoined
        "
      >
        <p
          class="mr-3 ml-3"
          @click="CancelSession(tr)"
          style="
            text-align: center;
            font-size: 15px;
            color: red;
            cursor: pointer;
          "
        >
          {{ $t("Cancel") }}
        </p>

        <p
          @click="EditSession(tr)"
          style="
            text-align: center;
            font-size: 15px;
            color: #ffffff;
            cursor: pointer;
          "
        >
          {{ $t("Edit") }}
        </p>
      </b-row>
    </div>
  </div>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>

  <vs-popup  title="" :active.sync="showEditSessionDialog">
      <EditSessionAppointmet
        v-if="showEditSessionDialog"
        @closePop="showEditSessionDialog = false"
        @SetNewSession="SetNewSession"
        :doctor="doctorModel"
        :editSessionModel="EditSessionModel"
        @successEdit="successEdit()"
      ></EditSessionAppointmet>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="showCancelDialog">
      <CancelSessionAlert
        v-if="showCancelDialog"
        @closePop="
          showCancelDialog = false;
          showConfirmMsg = false;
        "
        @CancelSessionDone="
          CloseSeccussCancelPopup();
          showCancelDialog = false;
        "
        :data="CancelSessionModel"
      ></CancelSessionAlert>
    </vs-popup>

    <!-- CancelledConfirm -->
    <vs-popup  title="" :active.sync="showConfirmMsg">
      <SuccessPopup
        :Message="$t('CancelledConfirm')"
        v-if="showConfirmMsg"
        @CloseSeccussPopup="CloseSeccussCancelPopup()"
        @closePop="showConfirmMsg = false"
        :data="CancelSessionModel"
      ></SuccessPopup>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowAlertPopup">
      <EditSessionAlert
        :Message="$t('CantEditSession')"
        v-if="ShowAlertPopup"
        @CloseSeccussPopup="
          ShowAlertPopup = false;
        "
      ></EditSessionAlert>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowJoinPopup">
      <JoinSessionAlertPopup
        :sessionModel="sessionModel"
        v-if="ShowJoinPopup"
        @closePop="ShowJoinPopup = false"
        @joinSession="ChangeReservationJoin"
      ></JoinSessionAlertPopup>
    </vs-popup> 
  </div>
</template>

<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import {
default as EditSessionAlert,
default as SuccessPopup
} from "@/views/pages/register/SuccessPopup.vue";
import CancelSessionAlert from "@/views/Patient/Reservation/CancelSessionAlert.vue";
import EditSessionAppointmet from "@/views/Patient/Reservation/EditSessionAppointmet.vue";
import JoinSessionAlertPopup from "@/views/Patient/Reservation/JoinSessionAlertPopup.vue";
import {
BAvatar,
BBadge,
BButton,
BCard,
BCol,
BDropdown,
BDropdownItem,
BFormInput,
BLink,
BMedia,
BPagination,
BRow,
BTable,
BTooltip
} from "bootstrap-vue";

export default {
  data() {
    return {
      tableColumns: [
        { key: "Doctors", label: this.$t("Doctors"), sortable: true },
        {
          key: "AppointmentDate",
          label: this.$t("AppointmentDate"),
          sortable: true,
        },
        { key: "Status", label: this.$t("Status"), sortable: true },
        {
          key: "PaymentStatus",
          label: this.$t("PaymentStatus"),
          sortable: true,
        },
        { key: "Time", label: this.$t("Time") },
        { key: "Actions", label: this.$t("Actions") },
      ],
      baseURL: domain,
      EditSessionModel: {},
      showEditSessionDialog: false,
      showCancelDialog: false,
      showConfirmMsg: false,
      doctorModel: {},
      ShowAlertPopup: false,
      ShowJoinPopup: false,
      CancelSessionModel: {},
      sessionModel: {},
    };
  },
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BTable,
    BTooltip,
    EditSessionAppointmet,
    CancelSessionAlert,
    EditSessionAlert,
    SuccessPopup,
    JoinSessionAlertPopup,
    imageLazy,
  },
  props: {
    Model: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ChangeReservationJoin(reservationId) {
      this.$store
        .dispatch("patientList/ChangeReservationJoin", reservationId)
        .then((res) => {
          this.$emit("refreshDataAfterCanceletion");
        });
    },

    CloseSeccussCancelPopup() {
      debugger;
      this.showConfirmMsg = false;
      // this.SearchAppointment();
      this.$emit("refreshDataAfterCanceletion");
    },
    SetNewSession(sessionModel) {
      this.showSetSession = true;
      this.showEditSessionDialog = false;
      this.sessionModel = sessionModel;
    },
    successEdit() {
      this.SearchAppointment();
    },
    PayNow(data) {
      data.FrontUrl = window.location.origin;
      this.$store
        .dispatch("patientList/AppointmentReservationPaymentDatail", data)
        .then((res) => {
          debugger;
          window.location.href = res.data.Data.transaction.url;
          // document.getElementById("amount").value = res.data.Data.amount;
          // document.getElementById("customer_email").value =
          //   res.data.Data.customer_email;
          // document.getElementById("signature").value = res.data.Data.signature;
          // document.getElementById("merchant_reference").value =
          //   res.data.Data.merchant_reference;
          // document.getElementById("paymentForm").submit();
        });
    },
    SearchAppointment() {
      debugger;
      var search = {
        patientID: this.$store.state.AppActiveUser.Patient.ID,
        IsNext: true,
        DateFrom: new Date(),
      };

      this.$store
        .dispatch("patientList/SearchPatientReservationSessions", search)
        .then((res) => {
          if (res.status == 200) {
            this.Model = res.data.Data;
            if (this.Model == null || this.Model.length == 0) {
              this.$vs.notify({
                title: this.$t("NoData"),
                text: this.$t("NoDataToshow"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          }
        })
        .catch(() => {
          window.showError();
        });
    },
    createSeeionMeeting(sesstionId) {
      this.$store
        .dispatch("auth/CreateMeetingSession", sesstionId)
        .then((res) => {
          if (res.status == 200) {
            this.sessionModel.JoinUrl = res.data.Data.AttendeeUrl;
            this.ShowJoinPopup = true;
          }
        });
    },
    joinMeeting(item) {
      debugger;
      this.sessionModel.ID = item.ID;
      this.sessionModel.JoinUrl = item.AttendeeUrl;

      var DateNow = new Date();
      debugger;

      var DateStart = new Date(item.DoctorSession.DateFrom);
      debugger;
      const milliseconds = DateStart - DateNow;

      var Minutes = Math.floor(milliseconds / 60000);

      if (Minutes <= 5) {
        this.createSeeionMeeting(item.ID);
      } else {
        this.$vs.notify({
          color: "danger",
          position: "right-bottom",
          text: "this meeting can't start now",
        });
      }
    },
    CancelSession(CancelSessionModel) {
      debugger;

      this.showEditSessionDialog = false;
      this.showCancelDialog = true;
      this.CancelSessionModel = CancelSessionModel;
    },

    checkDate(sesstionDate) {
      debugger;
      const current = new Date();
      sesstionDate = new Date(sesstionDate);
      var diffMs = sesstionDate - current;
      var diffMins = Math.round(diffMs / 60000);

      if (diffMins <= 5) return true;
      else return false;
    },
    EditSession(model) {
      debugger;
      var dayNow = new Date();
      var myDate = new Date(model.DoctorSession.DateFrom);
      dayNow = new Date(dayNow.getTime() + 3 * 24 * 60 * 60 * 1000);
      var date1 = dayNow.getTime();
      var date2 = myDate.getTime();
      if (date1 > date2) {
        this.ShowAlertPopup = true;
      } else {
        if (model.DoctorID > 0) {
          //doctorID
          this.EditSessionModel = model;
          this.EditSessionModel.viewType = 1;
          this.ViewEditSessionDialog(model.DoctorID);
        }
      }
    },

    ViewEditSessionDialog(doctorID) {
      debugger;

      this.$vs.loading();
      this.$store.dispatch("DoctorList/GetDoctor", doctorID).then((res) => {
        debugger;
        this.doctorModel = res.data.Data;
        this.showCancelDialog = false;
        this.showEditSessionDialog = true;
        debugger;
        this.$vs.loading.close();
      });
      debugger;

      this.$vs.loading.close();
      debugger;
    },
    RefershGrid() {
      this.$emit("SearchAppointment", true);
    },
  },

  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const StatusID = this.$route.params.StatusID;
    const ReservationID = this.$route.params.ReservationID;
    if (StatusID && ReservationID) {
      if (StatusID == 14) {
        this.$router.push({
          name: "BookingSummary",
          params: { Flag: 1, ID: ReservationID },
        });
      } else if (StatusID != 14 && ReservationID) {
        window.showError($t("Paymentfail"));
      }
    }
  },
};
</script>
<style>
.downloadCell {
  min-width: 50px;
  max-width: 60px;
  padding: 0px;
}
#RSession table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
  margin-left: 0.3rem;
  margin-bottom: 0.2rem;
}

#RSession td,
#RSession th {
  border-left: solid #00000029 1px;
  border-top: solid hsla(0, 0%, 0%, 0.161) 1px;
}

#RSession th {
  border-top: none;
}

#RSession th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#RSession th:last-child {
  border-top-right-radius: 36px;
}
#RSession tr:last-child td:last-child {
  border-bottom-right-radius: 36px;
}
#RSession td:first-child,
#RSession th:first-child {
  border-left: none;
}
#RSession th,
tr {
  height: 5rem;
}
.scroll {
  overflow-x: auto !important;
}
</style>


<style lang="scss">
.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
#page-user-list {
  .user-list-filters {
    height: 10px;

    .vs__actions {
      position: absolute;
      right: 0;
      top: 20%;
      height: 100px;
      transform: translateY(-58%);
    }
  }
}

#instant-search-demo {
  .header {
    .filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .price-slider {
    max-width: 80%;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #instant-search-demo {
    #content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color:#29b3ed;
  color: white;
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #content-container {
    .vs-sidebar {
      position: fixed !important;
      float: left !important;
    }
  }
}

.grid-view-item {
  // height: 450px;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: #29b3ed;
    .grid-view-img {
      opacity: 0.9;
    }
  }

  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
    .vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
  }
}
</style>
