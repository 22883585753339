<template>
  <div id="CancelPOP" class="containerr justify-center items-center">

      <!-- Please Note: you will cancel your booking reservation, System will remove your booking reservation details Thanks for using docLinia services we are looking forward to see you soon -->
      <img
        width="200"
        height="50"
        class="TextLogo"
        alt=""
        src="@/assets/images/footer_logo.png"
      />

    <div>
      <div class="text-center">
         <h2 class="m-5 " style="color: white">
           {{ $t("Cancellationconditionspop") }} {{ $t('ForPaidSession')}}
        </h2>
          <h2 style="color: white" v-if="data.PaymentStatusID==5">{{$t('SessionPrice')}}:{{data.TotalPriceUSD}}</h2>
        <ul class="m-5 ">
          <li style="color: white">
            {{ $t("AppointmentPatientConditionFirst") }}
          </li>
          <li style="color: white">
            {{ $t("AppointmentPatientConditionSecond") }}
          </li>
          <li style="color: white">
            {{ $t("AppointmentPatientConditionThird") }}
          </li>
        </ul>
        <!--
        <p class="m-5 P-Text" style="color: white">
          {{ $t("AppointmentPatientCondition") }}
        </p> -->
        <p class=" mt-8" style="color: white">
          {{ $t("confirmCancelSessionText1") }}
        </p>
        <p class=" mb-8" style="color: white">
          {{ $t("confirmCancelSessionText2") }}
        </p>

        <div class="">
          <u style="color: white;font-weight: bold" class="m-1" @click.stop="$emit('closePop')"
            >{{ $t("Back") }}
          </u>
          <vs-button
            class="m-1"
            color="white"
            style="width: 12%"
            @click="cancelSession"
          >
            <p style="color: #2753D9; font-weight: bold">{{ $t("Confirm") }}</p>
          </vs-button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";

export default {
  data() {
    return {
      baseURL: domain,
      ShowSuccessPopup: false,
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {

    cancelSession() {
      debugger;
      var obj = {};
      obj.PatientReservationSessionID = this.data.ID;
      obj.PatientID = this.data.PatientID;
      obj.DoctorSessionID = this.data.DoctorSessionID;
      obj.DoctorID = this.data.DoctorID;
      obj.StatusID = 3;

      this.$store
        .dispatch("patientList/CancelSession", obj)
        .then((res) => {
          debugger;
           this.$emit("CancelSessionDone");
        })
        .catch((err) => {
          debugger;
          window.showError();
        });

    },
  },
  mounted() {
    console.log(this.data);
  },
};
</script>
<style>
 .containerr {
  background: transparent radial-gradient(closest-side at 50% 50%, #2753D9 0%, #2753D9 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px;
  opacity: 1;
  text-align: center;
  width: 70%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
      margin: auto;
}
.containerr {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: inline-block;
  padding: 0.8rem;
  margin: 1%;
}
.TextLogo {
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight: bold;
  color: #2753D9;
}
.P-Text {
  color: white;
  text-align: center !important    ;
  font-size: 1.2rem;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 2rem;
}
</style>
