<template>
  <div>
    
    <div>
      <b-tabs  pills>
      <!-- Tab: UpCommingDates -->
      <b-tab active   @RefershGrid="SearchAppointment" @click="SearchAppointment(true)">
        <template #title>
          <feather-icon icon="CornerDownRightIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{$t('UpCommingDates') + '  (' + CommingCount + ')'}}</span>
        </template>
        <Reservation-Sessions class="mt-4"  @refreshDataAfterCanceletion="refreshDataAfterCanceletion()" :Model="Model"  />
      </b-tab>

      <!-- Tab: OldDates -->
      <b-tab   @click="SearchAppointment(false)">
        <template #title>
          <feather-icon icon="CornerDownLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('OldDates') + '  (' + OldCount + ')'}}</span>
        </template>
        <Reservation-Sessions class="mt-4"  :Model="Model" />
      </b-tab>

        <!-- Tab: cancelledBookings -->
        <b-tab  >
        <template #title>
          <feather-icon icon="CornerDownLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('cancelledBookings') + '  (' + canncelCount + ')'}}</span>
        </template>
        <Reservation-Sessions   class="mt-4" :Model="cancelModel" />
      </b-tab>

    </b-tabs>
     
    
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import { BAlert, BButton, BCard, BLink, BTab, BTabs } from "bootstrap-vue";

import { domain } from "@/gloabelConstant.js";
import ReservationSessions from "@/views/Patient/Reservation/ReservationSession.vue";
export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
      cancelModel: [],
      activeTab: 0,
      EnglishLan: false,
      OldCount: 0,
      CommingCount: 0,
      canncelCount: 0,
    };
  },
  components: {
    ReservationSessions, BAlert, BButton, BCard, BLink, BTab, BTabs
  },
  props: {

  },
  methods: {
    back() {
      debugger;
      this.$router.push("/patient/home");
    },
    SearchAppointment(isComming) {
      debugger;
      var search = {
        patientID: this.$store.state.AppActiveUser.Patient.ID,
        IsNext: false,
        PatientJoin: true,
      };
      this.$store
        .dispatch("patientList/SearchPatientReservationSessions", search)
        .then((res) => {
          debugger;
          if (res.status == 200)
          {

            this.OldCount = res.data.Data.length;
          }
        });

      if (isComming == true )
       {search.IsNext = true;search.PatientJoin=false ; search.DateFrom = new Date() }
      else
      {
        debugger;
        search.IsNext = false;
       search.PatientJoin=true;
      }

      this.$store
        .dispatch("patientList/SearchPatientReservationSessions", search)
        .then((res) => {
          if (res.status == 200) {
            this.Model = res.data.Data;
            if (this.Model == null || this.Model.length == 0) {
              // this.$vs.notify({
              //   title: this.$t("NoData"),
              //   text: this.$t("NoDataToshow"),
              //   iconPack: "feather",
              //   icon: "icon-alert-circle",
              //   color: "warning",
              // });
            }
          }
          debugger
          if (isComming == true) this.CommingCount = this.Model.length;
        })
        .catch(() => {
          window.showError();
        });
      this.SearchCancellAppointment();
    },
    refreshDataAfterCanceletion(){
      debugger
        this.SearchCancellAppointment();
        this.SearchAppointment(true);

    },
    SearchCancellAppointment() {
      debugger;
      var search = {
        patientID: this.$store.state.AppActiveUser.Patient.ID,
        StatusID: 3,
      };
      debugger;
      this.$store
        .dispatch(
          "patientList/SearchPatientCancelledReservationSessions",
          search
        )
        .then((res) => {
          if (res.status == 200) {
            debugger;
            this.cancelModel = res.data.Data;
            if (this.cancelModel == null || this.cancelModel.length == 0) {
              // this.$vs.notify({
              //   title: this.$t("NoData"),
              //   text: this.$t("NoDataToshow"),
              //   iconPack: "feather",
              //   icon: "icon-alert-circle",
              //   color: "warning",
              // });
            }
          }
          this.canncelCount = this.cancelModel.length;
          debugger;
        })
        .catch(() => {
          window.showError();
        });
    },
  },
  created() {
    debugger;
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.SearchAppointment(true);
  },
  
};
</script>
