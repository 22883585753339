<template>
  <div class="joincontainer justify-center items-center">
    
      <img
      width="40%" height="50%" 
       style="background-color: white;"
                alt="login"
                class="mx-auto mt-3"

        src="@/assets/images/footer_logo.png"
      />
    <div>
      <div class="text-center">
        <h2 class="m-2 ">{{ $t("OnlineAppointmentJoinAlert") }}</h2>

        <div class="">
          <u style="color: black"  @click.stop="$emit('closePop')"
            >{{ $t("Back") }}
          </u>

          <vs-button
            color="white"
            style=" text-align: center"
            @click="JoinSession()" 
          >
            <p style="color: #2753D9; font-weight: bold">{{ $t("Join") }}</p>
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
export default {
  data() {
    return {
      baseURL: domain,
    };
  },
  props: {
    sessionModel: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
   
    JoinSession() {
      debugger;
      this.sessionModel;
      window.open(this.sessionModel.JoinUrl, "_blank");
      this.$emit("joinSession",this.sessionModel.ID);
      this.$emit('closePop');
    },
  },
  created(){
   if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
  },
  mounted() {
    console.log(this.data);
  },
};
</script>
<style>
.joincontainer {
  opacity: 1;
  text-align: center;
}
.joincontainer {
  text-align: center;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: inline-block;
  padding: 0.8rem;
  margin: 1%;
}
.TextLogo {
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight: bold;
  color: #2753D9;
}
.P-Text {
  color: #ffffff;
  text-align: center !important    ;
  font-size: 1.2rem;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 2rem;
}
</style>
